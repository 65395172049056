<template>
  <div class="my-9 text-center">
    <h3 class="heading-4 mb-6">{{ $t('shop-by-category', 'Shop by Category') }}</h3>
    <div class="shop-by-category xl:container">
      <UiSwiper :config="swiperConfig" class="-mx-2 lg:-mx-4">
        <SwiperSlide v-for="category in swiperSignature" :key="category.id" class="px-2 lg:px-4 box-border">
          <NuxtLink :to="category.link" class="no-underline">
            <Image77 :path="category.image" :alt="category.title" imageClass="mb-3" />
            <h4 class="uppercase">{{ category.title }}</h4>
          </NuxtLink>
        </SwiperSlide>
      </UiSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import Image77 from '~/components/ui/Image77.vue'

import { getCategorySwiperSignature, categorySwiperOptions } from '@/constants/swiper/home'

const props = defineProps({
  swiperConfig: {
    type: Object,
    default: () => categorySwiperOptions,
  },
})

const swiperSignature = computed(() => getCategorySwiperSignature())
</script>

<style scoped></style>
